<template>
    <div>
        <div class="h" onselectstart="return false">
            <HeaderView z_active="5" />
        </div>

        <div class="ctop_b dis_column" onselectstart="return false">
            <img src="../assets/contact/top.png" style="width: 65px; height: 65px;"/>
            <div class="title_t">{{ $t('contactUs.title') }}</div>
            <!-- <div class="title_sec">在高德地图上找到我们</div> -->

            <!-- <img src="../assets/contact/star.png" class="star_l"/> -->
            <!-- <img src="../assets/contact/star.png" class="star_r"/> -->
        </div>

        <div id="container"></div>

        <div class="type_list dis_center" onselectstart="return false">
            <div class="dis_column type_item" v-for="(item, index) in type_list" :key="index">
                <img :src="item.img"/>
                <div class="type_t">{{ $t(item.title) }}</div>
                <div class="type_sec"
                :style="index== 1 && $i18n.locale== 'en'? 'zoom: .9;': ''">{{ $t(item.tip) }}</div>
            </div>
        </div>


        <el-form  class="form_b" :model="form_data" :rules="rules" ref="form_data">
            <div class="dis_column form_b_top" onselectstart="return false">
                <div class="lt">留言给我们</div>
                <div class="lsec">LEAVE A MESSAGE FOR US</div>
            </div>

            <div class="dis_between el_row">
                <el-form-item prop="name" >
                    <el-input class="s_inp"  v-model="form_data.name" 
                        :placeholder="$t('contactUs.placeholder1')" type="text"></el-input>
                </el-form-item>
                
                <el-form-item prop="tel" >
                    <el-input class="s_inp" v-model="form_data.tel" :placeholder="$t('contactUs.placeholder2')"
                        type="text" maxlength="11"></el-input>
                </el-form-item>
                <!-- <el-form-item prop="email" >
                    <el-input class="s_inp" v-model="form_data.email" placeholder="邮箱"
                        type="text"></el-input>
                </el-form-item> -->
            </div>

            <div style="width: 1200px; margin: 0 auto;">
                <el-form-item prop="tellus" >
                    <el-input class="area_inp" resize="none" type="textarea" 
                        :placeholder="$t('contactUs.placeholder3')" v-model="form_data.tellus"></el-input>
                </el-form-item>
            </div>
            

            <div class="sub_b dis_center" @click="sub('form_data')">{{ $t('contactUs.submit') }}</div>
        </el-form>

        <FooterView  />

    </div>
</template>

<script>

    import HeaderView from '@/components/HeaderModel.vue';
    import AMapLoader from '@amap/amap-jsapi-loader';
    import FooterView from '@/components/FooterModel.vue';

    import http from '@/utils/http.js';
    
    //安全密钥引入
    window._AMapSecurityConfig = {
        securityJsCode: "4e09a71a2c874766ba34f78a90791c19",
    };

    //姓名验证
    const checkName= (rule, value, callback)=> {
        if (!value) {
            return callback(new Error('姓名不能为空'))
        }
        callback();
    };

    //电话验证
    const checkPhone = (rule, value, callback) => {
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!value) {
        return callback(new Error('电话号码不能为空'))
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入正确的电话号码'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('电话号码格式不正确'))
          }
        }
      }, 100)
    }

    //邮箱验证
    const checkEmail = (rule, value, callback) => {
      if (!value) {
       return callback(new Error("邮箱不能为空"));
      }
      else {
        // const reg=/^[1-9][0-9]{4,}@qq.com$/
        const reg= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
        if(reg.test(value)){
          callback();
        }else {
            return callback(new Error("邮箱格式不正确"));
          } 
      }
    };

    //姓名验证
    const checkLeaveWord= (rule, value, callback)=> {
        if (!value) {
            return callback(new Error('请输入留言'))
        }
        callback();
    };
    

    export default{
        name: 'contactUs',
        components: {
            HeaderView,
            FooterView
        },  
        data(){
            return{
                type_list:[
                    {
                        img: require('../assets/contact/t1.png'),
                        title: 'contactUs.item_text1',
                        tip: 'contactUs.item_tip1'
                    },
                    {
                        img: require('../assets/contact/t2.png'),
                        title: 'contactUs.item_text2',
                        tip: 'contactUs.item_tip2'
                    },
                    {
                        img: require('../assets/contact/t3.png'),
                        title: 'contactUs.item_text3',
                        tip: 'contactUs.item_tip3'
                    },
                    {
                        img: require('../assets/contact/t4.png'),
                        title: 'contactUs.item_text4',
                        tip: 'contactUs.item_tip4'
                    },
                ],
                
                map: null, //高德实例
                marker: null, //点标记 Marker
                geocoder: null, //逆向地理
                infoWindow: null,  //弹窗
                po_img: require('../assets/poi-marker-default.png'),


                form_data: {
                    name: '',
                    tel: '',
                    // email: '',
                    tellus: '',
                },
                rules: {
                    name: [
                        { trigger: 'blur', validator: checkName },
                    ],
                    tel: [
                        { trigger: 'blur', validator: checkPhone }
                    ],
                    // email: [
                    //     { trigger: 'blur', validator: checkEmail }
                    // ],
                    tellus: [
                        { trigger: 'blur', validator: checkLeaveWord },
                    ]
                    
                },

            }
        },
        mounted(){
            document.title= '联系我们'
            this.initMap();
        },
        methods:{
            sub(formName){
                this.$refs[formName].validate((valid)=> {
                    if(valid){
                        if(this.combo_click){ return };
                        this.combo_click= true;

                        // 写死 记得删掉 ****
                        // this.$message.success('提交成功');
                        // this.$refs[formName].resetFields();
                        //  ****

                        // return;
                        let data= {
                            userName: this.form_data.name,
                            telephone: this.form_data.tel,
                            leaveInfo: this.form_data.tellus
                        }

                        http.post(http.url.hyRelation_add, data).then(res=> {
                            if(res.code== 200){
                                this.$message.success('提交成功');
                                this.$refs[formName].resetFields();

                            }else{
                                console.log(res.message);
                                this.$message.error(res.message);
                            }

                            this.combo_click= false;

                        })

                    }else{
                        // console.log('验证错误')
                        return false;
                    }
                })

                
            },
            initMap() {
                let vm= this;

                AMapLoader.load({
                    key: "0b13e3617c92483814585cc3565cdeb4", // 申请好的Web端开发者Key，首次调用 load 时必填
                    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    plugins: ["AMap.ElasticMarker", "AMap.Geocoder", "AMap.MarkerCluster", "AMap.CitySearch"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                }).then((AMap) => {
                    // 实例化
                    this.map = new AMap.Map("container", {//设置地图容器id
                        viewMode: "3D", //是否为3D地图模式
                        showBuildingBlock: true,
                        resizeEnable: true,
                        zoom: 16, //初始化地图级别
                        center: [126.551692, 45.810903], //初始化地图中心点位置
                    });
            
            
                    // 地图点击事件--点标记标注
                    // this.map.on("click", this.handleClick);
                    this.infoWindow = new AMap.InfoWindow({
                        offset: new AMap.Pixel(-6, -40),
                    });

                    this.marker = new AMap.Marker({
                        position: [126.551692, 45.810903],
                        // icon: "//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png",
                        icon: new AMap.Icon({
                            image: vm.po_img,
                            imageSize: new AMap.Size(25, 40),
                        }),
                        // offset: new AMap.Pixel(-13, -20)   
                        offset: new AMap.Pixel(-20, -40)
                    });


                    //新图  map_toast1.jpg  width: 300px
                    this.marker.content = `
                        <div class='toast_main' style="width: 230px; height: 240px; overflow: hidden;">
                            <img src="https://minio.zhongtianhuiyu.com/zthy/turn_table/map_toast.jpg" style="width: 100%; height: 220px; position: absolute; top: 0; left: 0;" />

                            <div style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%; height: 90px; position: absolute; bottom: 0; left: 0; background: #fff;">
                                <img src="https://minio.zhongtianhuiyu.com/zthy/turn_table/poi_icon.png" />
                                <div style="font-size: 15px; color: #07184D; font-weight: bold;">中天汇宇投资集团</div>
                                <div style="color: #3A3A3A; font-size: 12px; margin: 4px 0 4px;">黑龙江·哈尔滨·松北区·松北大道36号</div>
                            </div>

                        </div>
                    `;
                    

                    // <div style="color: #3A3A3A; font-size: 12px;">0451-82715555</div>


                    this.marker.on('click', this.markerClick)
                    this.marker.setMap(this.map);



                }).catch((e) => {
                    console.log(e);
                });
            },
            markerClick(e){
                // console.log(e)
                this.infoWindow.setContent(e.target.content);
                this.infoWindow.open(this.map, e.target.getPosition());

            },  
            handleClick(e) {
                let longitude = e.lnglat.getLng(); //经度
                let latitude = e.lnglat.getLat(); //纬度
                console.log(longitude, latitude)
                return;
                // 逆向地理编码
                this.geocoder.getAddress([longitude, latitude], (status, result) => {
                    if (status === "complete" && result.info == "OK") {
                        let address = result.regeocode.formattedAddress;
                        // 更新点标记
                        this.updateMap(address, [longitude, latitude]);
                    } else {
                        console.log("定位失败，请稍后重试");
                    }
                });
            },

            updateMap(address, lnglat) {
                // 移除已创建的 marker
                if (this.marker) this.map.remove(this.marker);
                // 同时设置地图中心点和缩放级别
                this.map.setZoomAndCenter(16, lnglat);
                // 自定义标记点
                this.marker = new AMap.Marker({
                    position: lnglat,
                    icon: "http://vdata.amap.com/icons/b18/1/2.png",
                    anchor: "top-center",
                    offset: new AMap.Pixel(-10, -10),
                });
                // 添加到实例
                this.marker.setMap(this.map);
                // 设置label标签，label默认蓝框白底左上角显示，样式className为：amap-marker-label
                this.marker.setLabel({
                    direction: "top-center",
                    offset: new AMap.Pixel(10, 0), //设置文本标注偏移量
                    content:"<div style='width:250px;'>" + address + "</div>", //设置文本标注内容
                });
            },
        },

    }
</script>


<style  scoped>
    .h{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 80px;
        z-index: 999;

    }

    .ctop_b{
        width: 100%;
        min-width: 1200px;
        height: 312px;
        height: 220px;
        background-color: #07184d;
        box-sizing: border-box;
        padding: 80px 0 0;
        position: relative;
        overflow: hidden;
    }
    .title_t{
        color: #fff;
        text-shadow: 0px 2px 2px rgba(0,0,0,0.1);
        /* font-size: 28px; */
        font-size: 20px;
        margin: 16px 0 9px;
    }
    .title_sec{
        font-size: 16px;
        color: #FFFFFF;
        text-shadow: 0px 2px 2px rgba(0,0,0,0.1);
    }

    .star_l,.star_r{
        position: absolute;
        z-index: 0;

    }
    .star_l{
        top: 15px;
        right: 85px;
        width: 405px;
        height: 397px;
    }
    .star_r{
        position: absolute;
        top: 160px;
        left: 0;
        width: 310px;
        height: 304px;
    }


    #container{
        width: 100%;
        height: 100vh;
        /*height: 900px;*/
    }
    #container /deep/ .amap-logo{
        display: none !important;
    }
    #container /deep/ .amap-copyright{
        display: none !important;
    }

    #container /deep/ .amap-info-content{
        border-radius: 8px;
    }


    .type_list{
        width: 100%;
        min-width: 1200px;
        height: 235px;
        background-color: #fff;
        box-shadow: 0px 2px 2px 1px rgba(0,0,0,0.1);
    }
    .type_item{
        width: 252px;
        height: 100%;
    }
    .type_t{
        font-size: 16px;
        color: #3a3a3a;
        text-shadow: 0px 2px 2px rgba(2,25,57,0.1);
        margin: 22px 0 6px;
    }
    .type_sec{
        color: #8B8B8B;
        font-size: 14px;
        text-shadow: 0px 2px 2px rgba(2,25,57,0.1);
        text-align: center;
    }

    .form_b{
        width: 100%;
        min-width: 1200px;
        background-color: #f9f9f9;
        padding: 67px 0 89px;
        box-sizing: border-box;
    }
    .form_b_top{
        width: 100%;
    }
    .lt{
        color: #666666;
        font-size: 28px;
        text-shadow: 0px 2px 2px rgba(2,25,57,0.1);
    }
    .lsec{
        color: #cccccc;
        font-size: 29px;
        text-shadow: 0px 2px 2px rgba(2,25,57,0.1);
        margin: 7px 0 40px;
    }
    .el_row{
        width: 1200px;
        margin: 0 auto 27px;
    }
    .s_inp{
        width: calc((1200px - 17px) / 2);
        height: 60px;
        box-shadow: 0 4px 6px rgba(0,0,0,0.1);
        font-size: 16px;
    }
    .s_inp /deep/ .el-input__inner{
        height: 60px;
    }

    .area_inp{
        width: 1200px;
        height: 260px;
        margin: 0 auto;
        display: block;
        box-shadow: 0 4px 12px rgba(0,0,0,0.1);
        font-size: 16px;
    }
    .area_inp /deep/ textarea{
        height: 100%;
        font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
        padding: 15px 15px;
          
    }
    .el-form-item{
        margin: 0;
    }

    .sub_b{
        width: 154px;
        height: 47px;
        background: url('../assets/contact/btn_bg.png') no-repeat top left;
        background-size: 100% 100%;
        color: #fff;
        border-radius: 47px;
        margin: 40px auto 0;
        font-size: 22px;
        cursor: pointer;
    }

    .toast_main{
        width: 100px;
        height: 150px;
    }
    .toast_main img{

    }


    @media screen and (max-width: 1300px) {
        .form_b{
            zoom: .8;
        }
    }

</style>