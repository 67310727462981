<template>
  <div class="header_top_b"  ref="header_top_b">
    <!-- onselectstart="return false" -->
    <div class="dis_between nav_b" ref="nav_b">
      <img src="../assets/logo.png" class="logo" /> 

      <div class="dis">
        <div class="dis" style="position: relative;">
          <div 
            class="item_b" 
            :class="index== active? 'ac': ''"
            v-for="( item, index ) in nav_list" 
            :key="index"
            @mouseenter="tab_hover(index)"
            @mouseout="tab_leave()"
            @click="c_tab(index, item.url)"
          >
          <!-- <img v-show="index== active" src="../assets/active_bg.png" class="active_bg" /> -->
              {{ $t(item.title) }}
          </div>
          <img :style="tab_left"  src="../assets/active_bg.png" class="active_bg" />

        </div>
        
        <div class="dis search_btn" v-show="!state" @click="start_search">
          <!-- <img src="../assets/search_icon.png" class="search_icon" /> -->
          <img src="../assets/search2.png" class="search_icon"/>
          <!-- <div class="search_text">搜索</div> -->
        </div>

        <div class="language_b" v-show="state">
          <div class="dis" style="height: 100%;" @click="change_language">
            <div class="lag_text">{{ $t('language.name') }}</div>
            <div class="z_down_icon" style="margin-left: 5px;" ref="down_icon"></div>
          </div>

          <div class="language_list" ref="language_list">
            <div class="language_item dis_center lang_line" @click="tap_change('cn')">中文</div>
            <div class="language_item dis_center" @click="tap_change('en')">English</div>
          </div>
          
        </div>

      </div>
    </div>


    <!-- 蒙层 -->
    <div class="mask" ref="mask"></div>


    <!-- 搜索模块 -->
    <div class="search_b" ref="search_b">
      <div class="ser_b ">
        <div class="ser_bbg"></div>
        <div class="dis_between" style="width: 100%; height: 44px;  padding: 0 24px; box-sizing: border-box;">
          <!-- <div class="dis" style="width: calc(100% - 100px); height: 100%;">
            <div class="ser_btn dis_center">
              <img src="../assets/search2.png" class="search_icon"/>
            </div> -->
            <input type="text" placeholder="请输入您感兴趣的投资公司" 
            @input="search_change($event)"
            ref="search_inp"/>
          <!-- </div> -->
          

          <div class="ser_x" @click="close_search" >
            <img src="../assets/ser_x.png" style="width: 100%; height: 100%;" v-show="back_status"/>
            <img src="../assets/back.png" style="width: 100%; height: 100%;" v-show="!back_status"/>
          </div>
        </div>
        
        <!-- 搜索模块 -->
        <div class="search_list" style="width: 100%;" v-show="search_list.length> 0">
          <div class="search_item dis" v-for="item in search_list" :key="item.id"
          :class="item.itemText== '暂无搜索结果'? 'dis_center': ''"
          @click="go_path(item)">
            <div class="search_res" v-show="item.itemText!='暂无搜索结果'">{{ item.itemText }}</div>
            <div class="search_nres" v-show="item.itemText=='暂无搜索结果'">{{ item.itemText }}</div>
          </div>
        </div>
      </div>

      

    </div>
    
</div>
</template>

<script>

import http from '../utils/http';

export default {
  name: 'HeaderModel',
  props:['z_active'],  
  data(){
      return{
          active: this.z_active,  //nav下标
          nav_list: [
              {
                  title: 'header.text1',
                  id: 0,
                  url: '/'
              },
              {
                  title: 'header.text2',
                  id: 1,
                  url: '/productCases'
              },
              {
                  title: 'header.text3',
                  id: 2,
                  url: '/aboutUs'
              },
              {
                  title: 'header.text4',
                  id: 3,
                  url: '/NewsList'
              },
              {
                  title: 'header.text5',
                  id: 4,
                  url: '/JoinUs'
              },
              {
                  title: 'header.text6',
                  id: 5,
                  url: '/contactUs'
              },
          ],
          state: false,  // false 显示搜索  true 显示翻译
          old_active: 0,
          timer: null,  //搜索防抖
          search_list: '',  //搜索数据
          back_status: false,  //false 展示返回  true展示 X

          language_state: false,  //语言栏下拉状态 
      }
  },
  computed:{
    tab_left: function(){
      return `left: calc(-18px + ${ this.active * 125 }px)`
    }
  },  
  methods: {
    search_change(){
      let val= this.$refs.search_inp.value
      this.back_status=  val.trim()== ''? false: true

      if(this.timer){
        clearTimeout(this.timer);
      }

      this.timer= setTimeout(() => {
        if(val.trim()){
          this.get_search_res(val.trim());
        }
        this.timer= null;
      }, 1000);

    },
    get_search_res(val){
      val= `,${val},`
      let data= {
        itemText: val,
        dictId: '1508342136323969025'
      }
      http.get(http.url.search_company, data).then(res=> {
        if(res.code=== 0 ){
          if(this.$refs.mask.style.display== 'none'){
            return;  //搜索框已关闭
          }
          let arr= []
          res.result.records.forEach(item=> {
            if(item.itemText== '哈尔滨环宇互娱有限公司' 
            || item.itemText== '哈尔滨沐知其生物科技有限公司'
            || item.itemText== '哈尔滨高美妍商贸有限公司'
            || item.itemText== '黑龙江靖峰科技有限公司'){
              arr.push(item)
            }
          })

          if(res.result.records.length== 0){
            arr.push({ itemText: '暂无搜索结果', id: `${Date.now()}-z` })
          }

          this.search_list= arr
          // console.log(this.search_list)
        }else{

        }
      })
    },  
    go_path(item){
      if(item.itemText== '暂无搜索结果')  return;
      this.close_search();

      if(item.itemText== '哈尔滨环宇互娱有限公司'){
        window.open("https://www.huanyuim.com/", "_blank");

      }else if(item.itemText== '哈尔滨沐知其生物科技有限公司'){
        window.open("http://www.hqmpet.com/", "_blank");

      }else if(item.itemText== '哈尔滨高美妍商贸有限公司'){
        window.open("http://gaomeiyan.com/", "_blank");
        
      }else if(item.itemText== '黑龙江靖峰科技有限公司'){
        window.open("http://cn.jfengkeji.com/", "_blank");
        
      }
      
      
    },  

    start_search(){
        this.$refs.nav_b.style.opacity= 0;
        this.$refs.mask.style.display= 'block';

        this.$refs.search_b.style.top= '28px';
        this.$refs.search_b.style.opacity= 1;
    },  
    close_search(){
        this.$refs.nav_b.style.opacity= 1;
        this.$refs.mask.style.display= 'none';

        this.$refs.search_b.style.top= '-120px';
        this.$refs.search_b.style.opacity= 0;

        this.search_list= [];
        this.$refs.search_inp.value= '';
        this.back_status= false;
    },
    
    c_tab(index, url){
      if(index== this.old_active){
        if(this.$route.name== 'jobList' || this.$route.name== 'jobDetail'){
          this.$router.replace(url)
        }
        return;
      }

      if(url){
        this.$router.replace(url)
        this.active= index
        this.old_active= index
      }

      // this.active= index
    },
    tab_hover(i){
      if(i== this.active){ return };
      this.active= i;

    },
    tab_leave(){
      this.active= this.old_active

    },
    /**
     * 加载导航状态
    */
    nav_state(){
      let _t= this.$route.meta.title;
      switch(_t){
        case '首页':
          this.active= 0;
          this.old_active= 0;
          break;
        case '产品案例':
          this.active= 1;
          this.old_active= 1;
          break;  
        case '关于我们':
          this.active= 2;
          this.old_active= 2;
          break; 
        case '新闻资讯':
          this.active= 3;
          this.old_active= 3;
          break;
        case '加入我们':
          this.active= 4;
          this.old_active= 4;
          break;
        case '联系我们':
          this.active= 5;
          this.old_active= 5;
          break;
      }
      
      this.state= _t== '中天汇宇投资集团'? false: true;

      // if(_t == '产品案例' || _t == '加入我们' || _t == '新闻资讯' ){
      //   this.$refs.header_top_b.style.border= 'none'
      // }
    },  

    change_language(){
      this.language_state= !this.language_state
      this.$refs.language_list.style.height= this.language_state? '80px': '0';
      this.$refs.language_list.style.opacity= this.language_state? '1': '0';
      this.$refs.down_icon.style.transform= this.language_state?'rotate(180deg)': 'rotate(0deg)';
    },

    tap_change(type){
      this.change_language()
      if(type== 'cn' && this.$i18n.locale=='zh'){ return };
      if(type== 'en' && this.$i18n.locale=='en'){ return };

      this.$i18n.locale=='zh'?this.$i18n.locale='en':this.$i18n.locale='zh'   //设置中英文模式
      localStorage.setItem('languageSet',this.$i18n.locale)   //将用户设置存储到localStorage以便用户下次打开时使用此设置
      
    }

  },
  mounted(){
    // console.log( document.body.clientWidth)
    let _w= document.body.clientWidth;
    
    if(_w< 1300){
      this.$refs.header_top_b.style.zoom= 0.9

    }

    this.nav_state()
    
  }
}
</script>

<style src="@/common/header.css" scoped></style>
