<template>
    <div class="body">
        <div class="stars">
            <div v-for="(item, index) in starsCount" :key="index" class="star" ref="star"></div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            starsCount: 500, //星星数量
            distance: 400 //间距
        };
    },
    mounted() {
        
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {

            this.starsCount= 100
            this.distance= 120
        }

        this.$nextTick(()=> {
            setTimeout(()=> {
                let starArr = this.$refs.star;
                starArr.forEach(item => {
                    let speed = 0.2 + (Math.random() * 1);
                    let thisDistance = this.distance + (Math.random() * 300);
                    let delay= `${ 300 + Math.random()*1000 }ms`;
                    item.style.animationDelay= delay;
                    item.style.transformOrigin = `0 0 ${thisDistance}px`;
                    item.style.transform = `
                            translate3d(0,0,-${thisDistance}px)
                            rotateY(${Math.random() * 360}deg)
                            rotateX(${Math.random() * -50}deg)
                            scale(${speed},${speed})`;
                })
            },200)
        })
        
    }
};

</script>


<style scoped>



.body {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    height: 600px;
    margin: 0;
    padding: 0;
    /* background: radial-gradient(200% 100% at bottom center,
            #f7f7b6,
            #e96f92,
            #1b2947);
    background: radial-gradient(200% 105% at top center,
            #1b2947 10%,
            #75517d 40%,
            #e96f92 65%,
            #f7f7b6); */
    background-attachment: fixed;
    overflow: hidden;
}

@keyframes rotate {
    0% {
        transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(0);
    }

    100% {
        /* transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(0deg); */
        transform: perspective(400px) rotateZ(20deg) rotateX(-40deg) rotateY(360deg);
    }
}

@keyframes flicker {
    0%,20%,40%,60%,80%,100%{
        opacity: 0;
    }

    10%,30%,50%,70%,90%{
        opacity: 1;
    }
}

.stars {
    transform: perspective(500px);
    transform-style: preserve-3d;
    position: absolute;
    perspective-origin: 0% 0%;
    /* perspective-origin: 50% 100%; */
    left: 45%;
    animation: rotate 90s infinite linear;
    bottom: 0;
}

.star {
    width: 2px;
    height: 2px;
    background: #f7f7b6;
    position: absolute;
    left: 0;
    top: 0;
    backface-visibility: hidden;
    opacity: .8;
    animation: flicker 8s ease-in-out infinite;
}


@media all and (orientation : landscape) and (max-width: 1000px) {
    .body{
        height: 100vh;
    }
}
@media screen and (max-width: 1000px) {
    .body{
        height: 500px;
    }
}

</style>