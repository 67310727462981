module.exports= {
    language: {
        name: 'English',
        wap_name: 'EN'
    },
    //头部导航
    header: {
        text1: 'Home',
        text2: 'Product',
        text3: 'About us',
        text4: 'News',
        text5: 'Join us',
        text6: 'Contact us',
    },


    //底部导航
    footer: {
        nav1: 'Product case',
        nav2: 'News',
        nav3: 'Contact us',
        nav4: 'Recruit',
        col1_text1: 'siyu IM',
        col1_text2: 'Hachma',
        col2_text1: 'company news',
        col2_text2: 'industry news',
        col3_text1: 'Company Profile',
        col3_text2: 'cooperate',
        col4_text1: 'Talent conce',
        col4_text2: 'Social recruitment',
        btm_text1: 'Black ICP No. 2021011899 - 1',
        btm_text2: 'Copyright: Harbin Zhongtian Huiyu Investment Group Co., Ltd',
        btm_text3: 'Zhongtian Huiyu WeChat official account',
    },


    //首页
    home: {
        mouse1: 'Mouse wheel',
        mouse2: 'control compass'
    },

    //关于我们
    about_us: {
        top_title1: 'Enterprise vision',
        top_dec1: 'Skilled in expertise, done in detail; Success lies in diligence and sincerity.',
        top_title2: 'Corporate mission',
        top_dec2: 'Equal emphasis on attack and defense, practical action by all staff, activity objectives, and service orientation.',
        top_title3: 'Enterprise values',
        top_dec3: '"Never lose originality, innovation comes first,  extensive observation leads to engagement, and thick  accumulation leads to thin development"',
        company_text: 'Harbin Zhongtian Huiyu Investment Group Co., LTD. (hereinafter referred to as the "Group") was established in March 2021, headquartered in the beautiful Harbin Ice City. As a comprehensive enterprise group, the group integrates manufacturing, research and development, trade and investment, involving the Internet, modern services, comprehensive pet services, beauty and skin care, catering management, science and technology entertainment and other industrial investment fields. Adhering to the business philosophy of "integrity, innovation, science and technology, stability and long term", and with the vision of "creating the most responsible investment service provider in China", the group is committed to the glorious mission of cultivating and developing the industry with resource advantages, expanding the company itself, creating value, serving the society and achieving employees. In the future, the Group will adhere to the development model of "Group operation - industrial investment - financial services - resource integration", and is committed to becoming a modern enterprise group with strong strength, excellent assets, standard management, good benefits and great influence. The Group will strive to build a solid investment company integrating science and technology construction, entity operation services and financial investment management.',
        btm_text1: 'Service hotline',
        btm_text2: 'Harbin Zhongtian Huiyu Investment Group Co., Ltd., focusing on investment in science and technology industry and application fields, has a high-quality professional team, a scientific man-agement system, and adheres to growing together with a res-ilien',
        btm_text3: 'Address: No. 36, Songbei Avenue, Songbei District, Harbin, Heilongjiang Province',
        btm_text4: 'service hours：working days：9：00am-17：30pm',
        btm_text5: 'email：zhongtianhuiyu@163.com',

    },

    //新闻资讯
    newsList: {
        tab1: 'Company News',
        tab2: 'Industry News',
    },


    //新闻详情
    newsDetail: {
        time: 'Time',
        back: 'Return to list'
    },


    //联系我们
    contactUs: {
        title: 'FIND US ON AMAPS',
        item_text1: 'E-mail',
        item_tip1: 'zhongtianhuiyu@163.com',
        item_text2: 'ADDRESS',
        item_tip2: 'No. 36, Songbei Avenue, Songbei District, Harbin Heilongjiang Province.',
        item_text3: 'service time',
        item_tip3: 'weekday：9：00-17：30',
        item_text4: 'telephone',
        item_tip4: '0451-82715555',
        placeholder1: 'your name',
        placeholder2: 'telephone number',
        placeholder3: 'message',
        submit: 'Submit'
    },


}