  <template>
    <div class="turn_po">
      <div 
        class="turn_table_b" 
        @mousewheel="onMousewhell($event)"
        onselectstart="return false"
      >
          <img src="../assets/turn_table/table_w.png" class="table_w"  ref="table_w" :style="'transform: rotate(' + deg + 'deg);' "/>
          <img src="../assets/turn_table/table_l.png" class="table_l" />
          <img src="../assets/turn_table/table_bg.png" class="table_bg"  />
          <img src="../assets/turn_table/table_r.png" class="table_r" />
          <img src="../assets/turn_table/table_body.png" class="table_body" :style="'transform: rotate(' + body_deg + 'deg);' ">

          <div class="table_conb">
            <img src="../assets/turn_table/table_h.png" class="table_h"  :style="'transform: rotate(' + body_deg + 'deg);' "/>
            <div class="table_list" :style="'transform: rotate(' + body_deg + 'deg);' ">
              <div class="table_itemb dis_center" 
              @click="tap_item(index)"
              v-for="( item, index ) in table_data" 
              :key="index" 
              :style="'transform:rotate('+ index* 90 +'deg)'">
                  <img :src="item.sm_logo" class="sm_logo" />
                  <!-- {{ item.title }} {{ index }} -->
                  <img src="../assets/turn_table/table_ac.png" class="table_ac" v-show="table_index== index"/>

              </div>

              <div class="table_center" :style="'transform: rotate(' + body_deg* -1 + 'deg);' ">
                <img src="../assets/turn_table/table_center.png" class="table_center_bg" />

                <div class="dis_column" ref="table_center" style="width: 100%; height: 100%;">
                  
                  <img 
                  :src="table_data[table_index].img" 
                  v-show="table_data[table_index].img" 
                  class="center_img"
                  />
                  
                  <div class="detail_btn dis_center" @click="go_path(table_data[table_index].url)">详情</div>
                </div>

              </div>


            </div>

          </div>


          

      </div>


      <div class="tip_b dis_column">
        <img src="../assets/turn_table/tip_light.png" class="tip_light" :style="'transform: rotate(' + tips_deg + 'deg);' "/>
        <img src="../assets/turn_table/tip_mouse.png" class="tip_mouse" ref="tip_mouse"/>
        <div class="tip_text" :style="$i18n.locale== 'en'? 'zoom: .8;': ''">{{ $t('home.mouse1') }}</div>
        <div class="tip_text" :style="$i18n.locale== 'en'? 'zoom: .8;': ''">{{ $t('home.mouse2') }}</div>
      </div>
    </div>

  </template>
  
  <script>
  export default {
    name: 'TurnTable',
    data(){
        return{
          deg: 360,  //光晕角度
          rotate_state: false,  //光晕旋转中状态,防止连续触发
          light_animation_time: 1,   //光晕旋转时间
          body_deg: 0, //转盘角度

          table_data: [
            {
              title: '环宇互娱',
              img: require('../assets/turn_table/big_logo0.png'),
              sm_logo: require('../assets/turn_table/small_logo0.png'),
              url: "https://www.huanyuim.com/"
            },
            {
              title: '高美研',
              img: require('../assets/turn_table/big_logo1.png'),
              sm_logo: require('../assets/turn_table/small_logo1.png'),
              url: "http://gaomeiyan.com/"
            },
            {
              title: '沐和其',
              img: require('../assets/turn_table/big_logo2.png'),
              sm_logo: require('../assets/turn_table/small_logo2.png'),
              url: "http://www.hqmpet.com/"
            },
            {
              title: '靖峰科技',
              img: require('../assets/turn_table/big_logo3.png'),
              sm_logo: require('../assets/turn_table/small_logo3.png'),
              url: "http://cn.jfengkeji.com/"
            },
          ],
          table_index: 0,  //转盘下标
          timeInterval: null,  //定时器
          timeOut: null,  //定时器重启防抖处理
          tip_Interval: null, //提示定时器
          tips_deg: 0, //提示光晕角度
          
        }
    },
    mounted(){
      // console.log('mounted')
      this.start_turn();
      this.tip_turn()
      document.addEventListener('visibilitychange', this.visibilitychange);
      this.opacity_show()


      
    },

    methods: {
      go_path(url){
        clearInterval(this.timeInterval);
        this.timeInterval= null;

        clearInterval(this.tip_Interval);
        this.tip_Interval= null;
        window.open(url, '_blank')
      },  
      visibilitychange(e){
        if(e.target.visibilityState== 'visible'){
          // console.log('回到当前标签');
          // console.log(this.timeInterval);
          if(!this.timeInterval){
            this.start_turn();
          }
          if(!this.tip_Interval){
            this.tip_turn()
          }
          
        }else{
          clearInterval(this.timeInterval);
          this.timeInterval= null;

          clearInterval(this.tip_Interval);
          this.tip_Interval= null;

          // console.log('离开当前标签')
        }
      },    
      /**
       * 自动旋转
      */
      start_turn(){
        
        this.timeInterval= setInterval(()=> {
          // console.log(1)
          this.deg= Number(this.deg) +360;
          this.body_deg= Number(this.body_deg)+ 90;
          this.table_index= this.table_index== 0? 3: this.table_index- 1

          this.opacity_show()
        },3500)

        

      },
      tip_turn(){
        this.tip_Interval= setInterval(() => {
          this.tips_deg= Number(this.tips_deg)+ 360;

          // this.$refs.tip_mouse.style.transition= 'none';
          this.$refs.tip_mouse.style.opacity= 0;

          setTimeout(() => {
            if(!this.$refs.tip_mouse){ return };
            // this.$refs.tip_mouse.style.transition= 'all 2s linear';
            this.$refs.tip_mouse.style.opacity= 1;
          }, 1500);

        }, 3000);
      },
      /**
       * 自动旋转防抖
      */
      restart_turn(){
        if(this.timeOut){
          clearTimeout(this.timeOut)
        }
        this.timeOut= setTimeout(() => {
          this.start_turn()
        }, 1500);
      },
      
      /**
       * 外圈光晕旋转效果
      */
      outside_light(e){
        let eventDelta = -e.wheelDelta || -e.deltaY
        
        this.deg= Number(this.deg)+  Number(eventDelta> 0? 360: -360);
        // console.log(this.deg)
      },

      /**
       * 实体转盘旋转效果
      */
      body_trun(e){
        let eventDelta = -e.wheelDelta || -e.deltaY
        // console.log(eventDelta)
        if(eventDelta> 0){ //顺时针
          if(this.table_index== 0){
            this.table_index= 3
          }else{
            this.table_index= this.table_index*1-1
          }
        }else{
          if(this.table_index== 3){
            this.table_index= 0
          }else{
            this.table_index= this.table_index*1+1
          }
          
        }
        
        
        this.body_deg= Number(this.body_deg)+  Number(eventDelta> 0? 90: -90);
        // console.log(this.body_deg)
        this.opacity_show()

      },

      /**
       * 中心渐显效果
      */
     opacity_show(){
        if(!this.$refs.table_center){ return };
        this.$refs.table_center.style.transition= 'none';
        this.$refs.table_center.style.opacity= 0;

        setTimeout(() => {
          if(!this.$refs.table_center){ return };
          this.$refs.table_center.style.transition= 'all .5s';
          this.$refs.table_center.style.opacity= 1;
        }, 200);
     }, 

      /**
       * 选中logo
      */
      tap_item(i){
        if(i== this.table_index){ return };
        clearInterval(this.timeInterval)

        let calc= Math.abs(i- this.table_index);
        // console.log(i)
        if(i> this.table_index){
          this.deg= Number(this.deg) -360;
          this.body_deg= Number(this.body_deg)- calc*90;
        }

        if(i< this.table_index){
          this.deg= Number(this.deg) +360;
          this.body_deg= Number(this.body_deg)+ calc*90;
        }

        this.table_index= i;
        this.opacity_show();
        this.restart_turn();
        
        
      },  
      onMousewhell(e){
         // wheelDelta的值为正（120.240...）则是鼠标向上；为负（-120，-240）则是向下。 
      // 因为要兼容浏览器，所以才写成 -e.wheelDelta || -e.deltaY.部分浏览器这个e.wheelDeltaY值是没有的可能是null，
      //部分浏览器e.deltaY这个值是没有的可能是null
      

        
        
        this.body_trun(e);
        if(this.rotate_state){ return };
        this.rotate_state= true;
        clearInterval(this.timeInterval);

        setTimeout(() => {
          this.rotate_state= false;
        }, this.light_animation_time* 1000);

        this.outside_light(e);
        
        this.restart_turn();
      },  
      


    },

    beforeDestroy(){
      clearInterval(this.timeInterval);
      this.timeInterval= null;
      document.removeEventListener('visibilitychange', this.visibilitychange);

      clearInterval(this.tip_Interval);
      this.tip_Interval= null;

    }


  }
  </script>
  
  <style src="../common/turnTable.css" scoped></style>
  