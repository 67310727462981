<template>
  <div class="home" user-drag="none">
    <!-- <img class="home_bg" src="../assets/home_img.png"> -->
    
    <div class="con_b">
      <!-- 头部导航 -->
      <HeaderModel z_active="0"/>

      <div class="con_center_b" ref="con_center_b" onselectstart="return false">
        <!-- 转盘 -->
        <TurnTable />
        
        <!-- 文字图片模块 -->
        <div class="dis_column" style="position: relative;">
          <img src="../assets/star.png" class="star"/>
          <img src="../assets/title_font.png" style="width: 574px; height: 79px;"  />
          <img src="../assets/title_font_light.png" class="light_img" />
          <img src="../assets/title_sec.png" style="width: 351px; height: 58px; margin: 27px 0 9px;" />
          <img src="../assets/title_thr.png" style="width: 389px; height: 21px;" />

        </div>
      </div>
    </div>
    
    
    <StarBg />
    <Meteor />
  </div>
</template>

<script>

import HeaderModel from '@/components/HeaderModel.vue';
import TurnTable from '@/components/TurnTable.vue';
import Meteor from '@/components/Meteor.vue';
import StarBg from '@/components/StarBg.vue';


export default {
  name: 'HomeView',
  components: {
    HeaderModel,
    TurnTable,
    Meteor,
    StarBg
  },
  beforeCreate(){
    document.title= '中天汇宇投资集团'
  },  
  mounted(){
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      // console.log('跳转到手机')
      let _r = this.$route.name;
      console.log(_r)
      if (_r === 'home'){
        this.$router.replace('/wap')
      }
      return;
    }



    document.body.style.overflow= 'hidden';
    // console.log( document.body.clientWidth)
    let _w= document.body.clientWidth;
    
    if(_w< 1300){
      this.$refs.con_center_b.style.zoom= 0.7
      this.$refs.con_center_b.style.justifyContent= 'space-evenly'

    }
    
  },  
  destroyed(){
    document.body.style.overflow= 'auto';
  }
}
</script>


<style scoped>
  html{
    overflow: auto;
  }
  body{
    overflow: hidden;
    user-select: none;
  }
  
  .home{
    width: 100%;
    height: 100vh;
    background:url(../assets/home_img.jpg) no-repeat center center fixed;
    background-size:cover;
    overflow: hidden;
  }
  .home_bg{
    width: auto;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
    overflow: hidden;
}

  .con_b{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .con_center_b{
    width: 100%;
    min-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15.037vh calc((100vw - 1200px) /2) 18.518vh;
    /* padding: 130px calc((100vw - 1200px) /2) 200px; */
    box-sizing: border-box;
    /* overflow: hidden; */
  }




  
  .dis_column{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .light_img{
    width: 830px;
    height: 346px;
    position: absolute;
    top: -78px;
    right: -230px;
    z-index: -1;
  }
  .star{
    width: 330px;
    height: 256px;
    position: absolute;
    top: -125px;
    right: 23px;
    z-index: -1;
  }


  

</style>