<template>
    <div class="footter" onselectstart="return false" :style="background? background: o_background">
        <div class="footer_conb dis_between">

            <img src="@/assets/footer/footer_logo.png" class="footer_logo"/>


            <div class="footer_cen">
                <div class="list_tab dis_between">
                    <div class="tab_item" v-for="(item, index) in tab_list" :key="index">{{ $t(item.title) }}</div>
                </div>

                <div class="list_tab dis_between" style="border: none; align-items: flex-start; margin-top: 10px;">
                    <div class="tab_items dis_column" v-for="( item, index ) in tab_item" :key="index">
                        <div class="tab_items_text" v-for="(items, indexs) in item" :key="indexs">{{ $t(items.title) }}</div>
                    </div>
                </div>

                <div class="dis_column" style="margin-top: 40px;">
                    <!-- <div class="ip">{{ $t('footer.btm_text1') }}</div> -->
                    <a href="https://beian.miit.gov.cn" target="_blank" class="ip">{{ $t('footer.btm_text1') }}</a>

                    
                    <div class="ip" style="margin-top: 7px;">{{ $t('footer.btm_text2') }}</div>
                </div>

            </div>

            <div class="dis_column" style="transform: translateY(10px);">
                <img src="@/assets/footer/smg.png" class="smg" />
                <div class="smg_text">{{ $t('footer.btm_text3') }}</div>
            </div>

        </div>
    </div>
</template>


<script>

    export default{
        data(){
            return{
                tab_list: [
                    { title: 'footer.nav1' },
                    { title: 'footer.nav2' },
                    { title: 'footer.nav3' },
                    { title: 'footer.nav4' }
                ],
                tab_item: [
                    [
                        { title: 'footer.col1_text1' },
                        { title: 'footer.col1_text2' },
                    ],
                    [
                        { title: 'footer.col2_text1' },
                        { title: 'footer.col2_text2' },
                    ],
                    [
                        { title: 'footer.col3_text1' },
                        { title: 'footer.col3_text2' },
                    ],
                    [
                        { title: 'footer.col4_text1' },
                        { title: 'footer.col4_text2' },
                    ]
                ],
                o_background: 'background-color: #e1f9ff;'
            }
        },
        props:['background'],
    }

</script>


<style scoped>
    .footter{
        width: 100%;
        height: 237px;
        position: relative;
        z-index: 1;
        min-width: 1200px;

    }
    .footer_conb{
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        padding: 17px 40px 0 0;
        box-sizing: border-box;
        align-items: flex-start;
    }

    .footer_logo{
        width: 146px;
        height: 47px;
        transform: translateY(70px);
    }

    .footer_cen{
        width: 790px;
    }
    .list_tab{
        width: 100%;
        height: 48px;
        border-bottom: 1px solid #007ffb;
        padding-left: 55px;
        box-sizing: border-box;
    }
    .tab_item{
        width: 128px;
        font-size: 18px;
        color: #329EFC;
        font-weight: bold;
        /* text-shadow: 0px 2px 2px rgba(3,42,75,0.1); */
        font-family: Segoe UI-Bold, Segoe UI;
    }
    .tab_items{
        width: 128px;
        font-size: 14px;
        color: #07184D;
        font-weight: 400;
        /* text-shadow: 0px 2px 2px rgba(3,42,75,0.1); */
        font-family: SimHei-Regular, SimHei;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .tab_items_text{
        margin: 4.5px 0;
    }
    .ip{
        font-size: 11px;
        font-family: DengXian-Bold, DengXian;
        font-weight: bold;
        color: #07184D;
        letter-spacing: 1px;
        text-decoration: unset;
        /* text-shadow: 0px 2px 2px rgba(0,0,0,0.1); */
    }


    .smg{
        width: 126px;
        height: 126px;
    }
    .smg_text{
        font-size: 12px;
        color: #07184D;
        margin-top: 10px;
        font-weight: bold;
        text-align: center;
    }
    @media screen and (max-width: 1300px) {
        .footer_conb {
            zoom: .9;
        }
    }
</style>