module.exports= {
    language: {
        name: 'CN',
        wap_name: 'CN'

    },
    //头部导航
    header: {
        text1: '首页',
        text2: '产品案例',
        text3: '关于我们',
        text4: '新闻资讯',
        text5: '加入我们',
        text6: '联系我们',
    },

    //底部导航
    footer: {
        nav1: '产品案例',
        nav2: '新闻动态',
        nav3: '联系我们',
        nav4: '招贤纳士',
        col1_text1: '思语 · IM',
        col1_text2: '哈奇马',
        col2_text1: '公司新闻',
        col2_text2: '行业行文',
        col3_text1: '公司简介',
        col3_text2: '合作洽谈',
        col4_text1: '人才理念',
        col4_text2: '社会招聘',
        btm_text1: '黑ICP备2021011899号-1',
        btm_text2: '版权所有：哈尔滨中天汇宇投资集团有限公司',
        btm_text3: '中天汇宇微信公众号',
    },

    //首页
    home: {
        mouse1: '请用鼠标滚轮',
        mouse2: '控制罗盘'
    },

    //关于我们
    about_us: {
        top_title1: '企业愿景',
        top_dec1: '技精于专，做于细；业成于勤，守于挚',
        top_title2: '企业使命',
        top_dec2: '攻守并重，全员实动，活动目标，服务导向',
        top_title3: '企业价值观',
        top_dec3: '匠心不泯，创新先行，博观而约取 厚积而薄发',
        company_text: '哈尔滨中天汇宇投资集团有限公司（以下简称“集团”）于2021年3月成立，总部位于美丽的哈尔滨冰城。作为一家综合企业集团，集团集制造、研发、贸易、投资于一体，涉及互联网、现代服务、宠物综合服务、美妆护肤、餐饮管理、科技娱乐等实业投资领域。集团秉承“诚信载道，开拓创新，科技建兴，行稳致远”的经营理念，并以“打造中国最具责任感的投资服务商”为愿景，致力于培育发展资源优势产业、壮大公司本体、创造价值、服务社会、成就员工的光荣使命。未来，集团将坚持“集团运营—产业投资—金融服务—资源整合”的发展模式，致力于成为实力雄厚、资产优良、管理规范、效益良好、影响力大的现代化企业集团。集团将全力打造科技建设、实体运营服务、金融投资管理为一体的实体化投资公司。',
        btm_text1: '服务热线',
        btm_text2: '哈尔滨中天汇宇投资集团有限公司，专注于科技产业及应用领域投资，拥有 高素质的专业化团队，科学的管理制度，坚持与坚韧明达的创业团队共同成长。',
        btm_text3: '地址：黑龙江省哈尔滨市松北区松北大道36号',
        btm_text4: '服务时间：工作日：9：00-17：30',
        btm_text5: '邮箱：zhongtianhuiyu@163.com',
    },

    //新闻资讯
    newsList: {
        tab1: '公司新闻',
        tab2: '行业新闻',
    },


    //新闻详情
    newsDetail: {
        time: '时间',
        back: '返回列表'
    },


    //联系我们
    contactUs: {
        title: '在高德地图上找到我们',
        item_text1: '邮箱',
        item_tip1: 'zhongtianhuiyu@163.com',
        item_text2: '地址',
        item_tip2: '黑龙江省哈尔滨市松北区松北大道36号',
        item_text3: '服务时间',
        item_tip3: '工作日：9：00-17：30',
        item_text4: '电话',
        item_tip4: '0451-82715555',
        placeholder1: '您的姓名',
        placeholder2: '电话号码',
        placeholder3: '你想说的话',
        submit: '提交留言'
    },

}